@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('/fonts/degular-v1.css');

html,
body {
  width: 100%; /* prevent width exceeding screen size when position: fixed; applied  */
  height: 100vh;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none; /* prevents iphone safari font size increase */
  overscroll-behavior-x: none;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: 1.4;
}

dt,
li {
  -webkit-text-size-adjust: 100%;
}

#map {
  width: 100%;
  height: 100%;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.show-print {
  display: none;
}

@media print {
  .hide-print {
    display: none !important;
  }

  .show-print {
    display: block !important;
  }
}

/* Hides mobile nav when virtual key is active */
[data-whatelement="input"] .hide-with-keyboard {
  display: none;
}

* {
  box-sizing: border-box;
  --toastify-icon-color-success: #fff;
}

#qc-cmp2-container * {
  font-family: Open Sans, Helvetica Neue, sans-serif;
}

[data-whatintent='touch'] *:focus,
[data-whatintent='mouse'] *:focus {
  outline: none;
}

.grecaptcha-badge {
  z-index: 150;
  visibility: hidden;
}

/* Default styles for featured development pages */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.pp-content-bottom,
.pp-content-middle {
  line-height: 1.42857143;
  overflow: hidden;
}

.pp-content-bottom img,
.pp-content-middle img {
  vertical-align: middle;
  border: 0;
}

.pp-content-bottom h1,
.pp-content-bottom h2,
.pp-content-bottom h3,
.pp-content-bottom h4,
.pp-content-bottom h5,
.pp-content-bottom h6,
.pp-content-middle h1,
.pp-content-middle h2,
.pp-content-middle h3,
.pp-content-middle h4,
.pp-content-middle h5,
.pp-content-middle h6 {
  font-weight:300;
}

.pp-content-bottom h1,
.pp-content-bottom h2,
.pp-content-bottom h3,
.pp-content-middle h1,
.pp-content-middle h2,
.pp-content-middle h3 {
  text-transform: uppercase;
}

.pp-content-bottom h1,
.pp-content-middle h1 {
  font-size: 2em;
  margin: 0 0 1em 0;
}

.pp-content-bottom h2,
.pp-content-middle h2 {
  font-size: 1.4em;
  margin: 0 0 1em 0;
  line-height:1.2em;
}

.pp-content-bottom h3,
.pp-content-middle h3 {
  margin: 1em 0;
  color: #666;
  font-size: 1.2em;
}

.pp-content-bottom h4,
.pp-content-middle h4 {
  font-size: 1.1em;
  margin: 0.5em 0;
}

.pp-content-bottom h6,
.pp-content-middle h6 {
  font-size: 1em;
  margin: 0.5em 0;
}

.pp-content-bottom hgroup h1,
.pp-content-bottom hgroup h2,
.pp-content-bottom hgroup h3,
.pp-content-bottom hgroup h4,
.pp-content-bottom hgroup h5,
.pp-content-bottom hgroup h6,
.pp-content-middle hgroup h1,
.pp-content-middle hgroup h2,
.pp-content-middle hgroup h3,
.pp-content-middle hgroup h4,
.pp-content-middle hgroup h5,
.pp-content-middle hgroup h6 {
  margin: 0 0 0.1em 0;
}

.pp-content-bottom p,
.pp-content-middle p {
  margin: 1em 0;
}

.pp-content-bottom hr,
.pp-content-middle hr {
  border-color: #ccc;
  border-width: 0 0 1px 0;
  margin: 0 0 2em 0;
  padding: 2em 0 0 0;
}

.pp-content-bottom ul li,
.pp-content-middle ul li {
  margin: 0.2em 0;
}

.pp-content-bottom small,
.pp-content-middle small {
  font-size: 90%;
  line-height: 1.3;
}

.pp-description-box,
.pp-similar-properties,
.pp-contact-box,
.pp-other-developments {
  background-color: #FFF;
}

@media (max-width: 1024px) {
  .videoask-embed__button--nnvG1 {
    margin-bottom: 60px;
  }
}

